@import 'styles/colors';

.root {
  position: relative;
}

.trigger:hover + .tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  z-index: 2;
  transform: translate(-50%);
  left: 65%;
  top: 100%;
  margin-top: 4px;
  padding: 8px;
  border-radius: 8px;
  color: $white;
  background-color: $black;
}

.left {
  transform: none;
  right: 100%;
  left: auto;
  margin: 0 20px 0 0;
  bottom: 0;
  top: auto;
}

.right {
  transform: none;
  left: 100%;
  right: auto;
  margin: 0 20px 0 0;
  bottom: 0;
  top: auto;
}

.top {
  bottom: 100%;
  top: auto;
  margin: 0 0 20px 0;
}

.large {
  width: 400px;
  text-wrap: wrap;
  line-height: 1.5;
}

.custom {
  position: fixed;
  top: auto;
  left: 80%;
}
