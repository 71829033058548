@import 'styles/colors';
@import 'styles/fonts';

.root {
  @include textMedium;
  position: fixed;
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 35px;
  border-radius: 12px;
  min-width: 120px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid $light-grey;
  background-color: $white;
  z-index: 2;
}

.filterOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
}

.resetFiltersButton {
  @include headingSmall;
  color: $blue;
  cursor: pointer;
  margin-top: 8px;

  &:hover {
    opacity: 0.8;
  }
}
