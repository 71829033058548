@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  background-color: $light-grey-2;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 40px 26px;
  overflow-y: auto;
}

.heading {
  @include headingSmall;
  color: $grey-2;
  text-transform: uppercase;
}

.campaignsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 35px;
}
