@import 'styles/colors';

.root {
  position: relative;
}

.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: transparentize($grey, 0.2);
  cursor: default;
}

.modalBody {
  position: relative;
  width: 460px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: solid 1px $light-grey;
  background-color: $white;
  gap: 20px;
}

.modalBodyForm {
  position: relative;
  width: 600px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: solid 1px $light-grey;
  background-color: $white;
  gap: 20px;
}

.modalContent {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
}

.modalAction {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  button {
    border: 0;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 30px;
    border-radius: 7px;
    background-color: $grey;
    color: $white;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }

  .confirmButton {
    background-color: $blue;
  }

  .cancelButton {
    background-color: lighten($red, 0.2);
  }
}

.modalRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  width: 30px;
  top: 20px;
  right: 20px;
  fill: $dark-grey;
}
