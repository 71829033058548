@import './colors';

@mixin resetFont {
  letter-spacing: normal;
  text-transform: none;
  color: $black;
}

@mixin heading {
  color: $black;
  font-size: 36px;
  font-weight: 600;
}

@mixin headingMedium {
  @include resetFont;
  font-size: 18px;
  font-weight: 600;
}

@mixin headingLarge {
  @include resetFont;
  font-size: 28px;
  font-weight: 600;
}

@mixin headingSmall {
  @include resetFont;
  font-size: 14px;
  font-weight: 600;
}

@mixin textMedium {
  @include resetFont;
  font-size: 14px;
  font-weight: normal;
}

@mixin textLarge {
  @include resetFont;
  font-size: 18px;
  font-weight: normal;
}

@mixin textSmall {
  @include resetFont;
  font-size: 12px;
  font-weight: normal;
}
