@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  display: flex;
  gap: 30px;
  padding: 14px;
  height: 100%;
  width: 100%;
}

.settings {
  width: 500px;

  input:disabled,
  textarea:disabled {
    color: $grey;
  }
}

.basic {
  margin-bottom: 30px;
}

.inlineContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionButton {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: $text-default-color;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  height: 150px;
}

.headerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $light-grey-2;
  width: 500px;
  height: 290px;
  min-height: 280px;
  padding: 20px 15px 15px;
  border-radius: 15px;
}

.heading {
  font-size: 16px;
  font-weight: bold;
  color: $text-default-color;
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.formInput {
  width: 100%;
  margin: 10px 0;
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
  text-wrap: nowrap;
}

.submitButton {
  margin: 20px 0;
  width: 100%;
}
