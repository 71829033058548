@import '../../styles/colors';
@import '../../styles/fonts';

.root {
  display: inline-block;
  width: 100%;
}

.select {
  @include textMedium;
  line-height: 1.25;

  &:focus-visible {
    border-color: $grey;
  }

  [class$='-indicatorSeparator'] {
    display: none;
  }

  [class$='-control'] {
    border-radius: 8px;
    border-color: $light-grey;
    width: 100%;
  }

  [class$='-multiValue'] {
    background-color: $cyan;
    border-radius: 5px;

    [class^='css'] {
      @include textMedium;
      color: $white;
    }
  }
}

.hasError [class$='-control'] {
  border-color: $red;
}

.errorMessage {
  @include textSmall;
  position: absolute;
  color: $red;
  margin: 5px 0;
}

.defaultValue {
  display: none;
}
