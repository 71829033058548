@import 'styles/colors';
@import 'styles/fonts';

.root {
  width: 100%;
}

.table {
  width: 100%;
  overflow: hidden;
}

.tableHolder {
  overflow: scroll;
  position: relative;
}

.tableHeaderHolder {
  margin-right: 10px;
  overflow: scroll;
  position: sticky;
  top: 0;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.noEntriesBlock {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stickyLabel {
  @include headingMedium;
  position: sticky;
  color: $grey;
}

.table th {
  position: relative;

  .resizer {
    /* Displayed at the right side of column */
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 40px;
    cursor: col-resize;
    user-select: none;
    background: #f1f1f1;

    &:hover {
      border-right: 2px solid #ccc;
    }
  }

  .resizing {
    border-right: 2px solid #ccc;
  }

  &:last-child {
    .resizer {
      display: none;
    }
  }
}
