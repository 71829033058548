@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 480px;
}

.selectGroupWrap {
  margin-top: -15px;
  margin-bottom: -15px;
}

.formGroupWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  input {
    margin-right: 20px;
  }

  .formInput {
    margin: 3px 0 0 0;
    min-width: 140px;
  }
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 0 18px 0;

  .inputHolder {
    position: relative;

    .deleteInputValue {
      position: absolute;
      height: 20px;
      width: 20px;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
      fill: #ccc;
    }
  }
}

.formInput {
  width: 100%;
  // margin: 5px 0;
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  margin-bottom: 2px;
  color: $grey-2;
  display: block;
  margin-bottom: 5px;
}

.textInput {
  padding: 10px 0 0;
  font-size: 14px;
}

.submitButton {
  width: 240px;
  margin-top: 15px;
}

.selectGroupWrapper {
  [class*='multiValue'] {
    background: none;

    &:not(:first-child) {
      display: none;
    }
  }

  label {
    display: flex;
    gap: 15px;

    input {
      width: 15px;
      height: 15px;
    }

    span {
      display: block;
      width: 100%;
    }
  }
}

.selectGroupHead {
  display: flex;
  gap: 15px;

  input {
    width: 15px;
    height: 15px;
  }

  > span {
    white-space: nowrap;
  }
}

.selectGroupHeadAction {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  i {
    width: 20px;
  }
}

.selectGroupHeadActionDefault {
  transform: rotate(0deg);
}

.selectGroupHeadActionActive {
  transform: rotate(180deg);
}

.selectGroupOptions {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.selectGroupOptionItem {
  display: flex;
  width: 100%;
  padding-left: 10px;
  align-items: center;

  input {
  }

  span {
    display: flex;
    font-size: 14px;
  }
}

.selectGroupOptionItemCounter {
  font-size: 12px;
  color: $blue;
  text-transform: none;
}

.selectCountriesPlaceholder {
  p {
    color: $grey;
    font-size: 12px;
  }
}

.timezoneStyle {
  width: 100px;
  margin: 0 5px;
}
