@import '../../../../../styles/colors';
@import '../../../../../styles/fonts';

.cellContent {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  position: relative;
}

.root {
  @include textSmall;
  color: $white;
  font-weight: 700;
  letter-spacing: 1px;
  height: 40px;
  text-transform: uppercase;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }
}

.iconHolder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.disabled {
    cursor: initial;
  }
}

.sortHolder {
  margin-left: 5px;
}

.filterHolder {
  margin-right: 5px;
}

.unsortedBlock {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

.unsortedArrow {
  margin-bottom: -10px;
}

.filterIcon {
  fill: $white;
  width: 16px;
  margin-bottom: -6px;
  transform: translateY(-2px);
}

.sortArrow {
  fill: $white;
  width: 16px;
  transition: all 0.2s;

  &.up {
    transform: rotate(180deg);
  }
}

.overlay {
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $grey;
  opacity: 0.4;
  z-index: 1;
}
