@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  margin-top: 10px;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.form {
  gap: 20px;
  display: flex;
  flex-direction: row;
}

.label {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
  margin-bottom: 8px;
}

.smallInput {
  width: 255px;
}

.inputHolderGrow {
  flex-grow: 1;
  max-width: 580px;
}

.submit {
  width: 140px;
}

.checkboxInput {
  label {
    display: flex;
    gap: 5px;
    align-items: center;

    input {
      width: 16px;
      height: 16px;
    }

    p {
      margin: 0;
    }
  }
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 0 18px 0;

  .inputHolder {
    position: relative;

    .deleteInputValue {
      position: absolute;
      height: 20px;
      width: 20px;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
      fill: #ccc;
    }
  }
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  margin-bottom: 2px;
  color: $grey-2;
}

.textInput {
  padding: 10px 0 0;
  font-size: 14px;
}
