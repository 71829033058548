@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  width: 100%;
  height: 100%;
  background-color: $white;
}

.formInputContainer {
  margin: 18px 0 0;

  &:first-child {
    margin: 0;
  }
}

.formInputContainerInline {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0 0;
}

.formInput {
  width: 100%;
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
  text-wrap: nowrap;
  display: block;
  margin-bottom: 5px;
}

.textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  height: 150px;
}

.submitButton {
  width: 100%;
}

.inlineActions {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
}

.red {
  color: $red;
  font-size: 12px;
}

.error {
  color: $red;
  font-size: 12px;
  margin: 5px 0;
}

.fakeSwitcher {
  display: flex;
  position: relative;
  width: 50px;
  height: 22px;
  background: $grey;
  border-radius: 22px;
  appearance: none;

  &:before {
    content: '';
    width: 17px;
    height: 17px;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    right: auto;
    background-color: $white;
    position: absolute;
  }

  &.fakeSwitcherEnabled {
    background: $green;

    &:before {
      right: 3px;
      left: auto;
    }
  }
}
