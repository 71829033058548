@import '../../../../styles/fonts';
@import '../../../../styles/colors';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 87px;
  border-radius: 20px;
  padding: 18px 28px 20px;
  gap: 20px;
}

.campaignImg {
  width: 45px;
  height: 45px;
  border-radius: 15px;
  object-fit: contain;
}

.campaignName {
  @include headingMedium;
}

.campaignManagerLabel {
  @include textMedium;
  text-transform: uppercase;
  color: $grey;
}

button.editButton {
  margin-left: 32px;
}

.campaignType {
  margin: 0;
  padding: 5px 10px;
  background-color: $cyan;
  color: $white;
  border-radius: 5px;
  text-transform: uppercase;
}
