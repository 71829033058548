$action-button-colors: (
  'action-bg-color-default': #1595ea,
  'action-bg-color-hover': #1581c9,
  'action-bg-color-disabled': #d1d1d1,
  'action-font-color-default': #ffffff,
  'action-font-color-hover': #ffffff,
  'action-font-color-disabled': #ffffff,
);

$primary-button-colors: (
  'primary-bg-color-default': #ffffff,
  'primary-bg-color-hover': #009ba4,
  'primary-bg-color-disabled': #ffffff,
  'primary-font-color-default': #009ba4,
  'primary-font-color-hover': #ffffff,
  'primary-font-color-disabled': #d1d1d1,
);

$danger-button-colors: (
  'danger-bg-color-default': #dd3939,
  'danger-bg-color-hover': #af2323,
  'danger-bg-color-disabled': #d1d1d1,
  'danger-font-color-default': #ffffff,
  'danger-font-color-hover': #ffffff,
  'danger-font-color-disabled': #ffffff,
);

$text-button-colors: (
  'text-bg-color-default': rgba(255, 255, 255, 0),
  'text-bg-color-hover': #e9f7f8,
  'text-bg-color-disabled': rgba(255, 255, 255, 0),
  'text-font-color-default': #009ba4,
  'text-font-color-hover': #009ba4,
  'text-font-color-disabled': #d1d1d1,
);

$secondary-button-colors: (
  'secondary-bg-color-default': #fff,
  'secondary-bg-color-hover': #1560e2,
  'secondary-bg-color-disabled': #f1f1f1,
  'secondary-font-color-default': #1560e2,
  'secondary-font-color-hover': #fff,
  'secondary-font-color-disabled': #eeeeee,
);

$large-button-sizes: (
  'medium-button-font-size': 14px,
  'large-button-height': 40px,
  'large-button-padding': 0 16px,
  'large-button-min-width': 100px,
  'large-button-icon-margin': 12px,
);
$medium-button-sizes: (
  'medium-button-font-size': 14px,
  'medium-button-height': 32px,
  'medium-button-padding': 0 12px,
  'medium-button-min-width': 80px,
  'medium-button-icon-margin': 10px,
);
$small-button-sizes: (
  'small-button-font-size': 12px,
  'small-button-height': 20px,
  'small-button-padding': 0 10px,
  'small-button-min-width': 50px,
  'small-button-icon-margin': 6px,
);

$button-colors-map: map-merge(
  map-merge(
    map-merge(map-merge($action-button-colors, $primary-button-colors), $text-button-colors),
    $danger-button-colors
  ),
  $secondary-button-colors
);

$button-sizes-map: map-merge(map-merge($large-button-sizes, $medium-button-sizes), $small-button-sizes);

@mixin button-stylings($type) {
  background: map-get($button-colors-map, '#{$type}-bg-color-default');
  color: map-get($button-colors-map, '#{$type}-font-color-default');

  svg {
    fill: map-get($button-colors-map, '#{$type}-font-color-default');
  }

  .iconHolder {
    color: map-get($button-colors-map, '#{$type}-font-color-default');
  }

  @if ($type == 'primary') {
    border: 1px solid $primary-border-color;
    box-shadow: 0 3px 6px 0 $primary-shadow-color;
  } @else if($type == 'secondary') {
    border: solid 1px $secondary-border-color;
  } @else {
    border: 1px solid transparent;
    box-shadow: none;
  }

  @if ($type == 'text') {
    font-weight: normal;
  } @else {
    font-weight: 500;
  }

  &:hover {
    background: map-get($button-colors-map, '#{$type}-bg-color-hover');
    color: map-get($button-colors-map, '#{$type}-font-color-hover');

    svg {
      fill: map-get($button-colors-map, '#{$type}-font-color-hover');
    }

    .iconHolder {
      color: map-get($button-colors-map, '#{$type}-font-color-hover');
    }

    box-shadow: none;

    &:not(:disabled) {
      border: 1px solid transparent;
    }
  }

  &:disabled {
    background: map-get($button-colors-map, '#{$type}-bg-color-disabled');
    color: map-get($button-colors-map, '#{$type}-font-color-disabled');

    svg {
      fill: map-get($button-colors-map, '#{$type}-font-color-disabled');
    }

    box-shadow: none;
    cursor: default;
  }
}

@mixin button-size($size) {
  height: map-get($button-sizes-map, '#{$size}-button-height');
  padding: map-get($button-sizes-map, '#{$size}-button-padding');
  min-width: map-get($button-sizes-map, '#{$size}-button-min-width');
  font-size: map-get($button-sizes-map, '#{$size}-button-font-size');

  .iconHolder {
    &.left {
      margin-right: map-get($button-sizes-map, '#{$size}-button-icon-margin');
    }

    &.right {
      margin-left: map-get($button-sizes-map, '#{$size}-button-icon-margin');
    }
  }
}

$sizes: ('large', 'medium', 'small');
$types: ('primary', 'text', 'action', 'secondary', 'danger');

$primary-border-color: #d9d9d9;
$primary-shadow-color: #f3f3f3;
$secondary-border-color: #1581c9;

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 12px;
  cursor: pointer;
  transition: color 0.1s, background 0.1s;

  @include button-stylings('primary');
  @include button-size('medium');

  @each $type in $types {
    &.#{$type} {
      @include button-stylings($type);
    }
  }

  @each $size in $sizes {
    &.#{$size} {
      @include button-size($size);
    }
  }

  svg {
    transition: fill 0.1s;
  }

  .iconHolder {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
