@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dndArea {
  height: 250px;
  border-radius: 15px;
  background-color: $white;
  border: 1px dashed $grey-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: all 0.2s;

  &.dragActive {
    border-color: $green;
    border-width: 3px;
  }
}

.heading {
  @include headingLarge;
  text-align: center;
}

.text {
  @include textMedium;
  text-align: center;
  color: $grey;
}

.chooseFileButton {
  width: 172px;
}

.label {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
  margin-bottom: 8px;
}

.smallInput {
  width: 255px;
}

.submit {
  width: 140px;
}

.checkboxInput {
  label {
    display: flex;
    gap: 5px;
    align-items: center;

    input {
      width: 16px;
      height: 16px;
    }

    p {
      margin: 0;
    }
  }
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 0 18px 0;

  .inputHolder {
    position: relative;

    .deleteInputValue {
      position: absolute;
      height: 20px;
      width: 20px;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
      fill: #ccc;
    }
  }
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  margin-bottom: 2px;
  color: $grey-2;
}

.textInput {
  padding: 10px 0 0;
  font-size: 14px;
}

.red {
  color: $red;
  font-size: 12px;
}

.inline {
  display: flex;
  gap: 10px;
}
