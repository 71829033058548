@import '../../../../styles/fonts';
@import '../../../../styles/colors';

.editIcon {
  height: 20px;
  margin: 0 10px 0;
  cursor: pointer;
  fill: $cyan;
}

.rewardActive {
  color: $green;
}

.rewardPending,
.rewardPaused {
  color: $orange;
}

.rewardExpired {
  color: $red;
}

.rewardDisabled,
.rewardEmpty {
  color: $red;
  font-weight: bold;
}

.dateTootip {
  @include textSmall;
  color: $white;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.controlsHolder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: -30px 0 10px 0;
}

.percentageColumn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .winMax {
    min-width: 50px;
  }
}

.rewardIdCol {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.copyButton {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.copyIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;

  &:active {
    path {
      fill: $cyan;
    }
  }
}

.root :global(table) {
  table-layout: fixed;
}

.root :global {
  th {
    position: sticky;
  }

  td {
    text-align: left;
    padding: 0 10px;
  }

  td:nth-child(1) {
    text-align: center;
    padding: 0;
  }

  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(11) {
    overflow: hidden;
    white-space: nowrap;

    span {
      padding: 0 10px 0 0;
    }

    .truncate {
      display: block;
      padding: 5px 10px 5px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.roundButtonHolder {
  display: flex;
  align-items: center;
  height: 0;
  grid-gap: 16px;
  gap: 16px;
  position: sticky;
  bottom: 36px;
  margin-top: -80px;

  button {
    right: 15px;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: 0.2s;
    height: 54px;
    width: 54px;

    &:hover {
      opacity: 0.9;
    }

    span {
      color: #fff;
      font-size: 32px;
      line-height: 32px;
    }
  }
}

.roundButton {
  height: 44px;
  width: 44px;
  border: none;
  outline: none;
  cursor: pointer;
  background: $blue;
  border-radius: 100%;
  box-shadow: 0px 5px 10px -5px $grey;
  transition: all 0.3s;

  svg {
    fill: $white;
    height: 24px;
    width: 24px;
  }

  &:disabled {
    cursor: default;
    background-color: $grey-2;
  }
}
