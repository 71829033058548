@import '../../styles/fonts';

.root {
  padding: 0 20px 20px 85px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.campaignHeaderHolder {
  position: relative;
  height: 70px;
  width: 100%;
}

.campaignContentHolder {
  flex-grow: 1;
  width: 100%;
}

.arrowLeft {
  width: 40px;
  height: 40px;
}

.heading {
  @include headingLarge;
  text-align: center;
}

.errorHolder {
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
