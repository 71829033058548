@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 18px 0 0;
}

.formInput {
  width: 100%;
}

.textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  height: 150px;
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
  margin: 10px 0;
}

.submitButton {
  margin-top: 15px;
  width: 204px;
}

.inputHolder {
  width: 100%;
  position: relative;
}

.inlineActions {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.red {
  color: $red;
}

.warning {
  color: $orange;
  font-size: 12px;
  margin-top: 5px;
}

.error {
  @include textSmall;
  color: $red;
  margin: 5px 0;
}
