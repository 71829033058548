@import '../../styles/colors';

.root {
  padding: 80px 20px 40px 85px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  overflow: hidden;
}

.campaignsListHolder {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.container {
  background-color: $light-grey-2;
  border-radius: 20px;
  padding: 40px 26px;
  width: 100%;
}

.editCampaignHolder {
  max-width: 350px;
  visibility: visible;
  transition: all 0.2s;
  position: relative;

  &.collapsed {
    max-width: 0;
    visibility: hidden;
  }
}

.skeleton {
  background: linear-gradient($light-grey, $white 50%, $light-grey 70%);
  background-size: 100% 200%;
  animation: gradient 1s linear infinite;

  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

@keyframes gradient {
  0% {
    background-position: 0% 200%;
  }

  100% {
    background-position: 0% 0;
  }
}

.placeholder {
  opacity: 0.18;
  text-align: center;
  margin-top: 20px;
  font-size: 48px;
  font-weight: 900;
  font-family: 'Helvetica', sans-serif;
  color: #12284b;
}
