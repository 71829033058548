@import 'styles/colors';
@import 'styles/fonts';

.tableRow {
  @include textSmall;
  height: 40px;
  transition: all 0.2s;

  &:nth-child(odd) {
    background-color: $light-grey-3;
  }

  &:nth-child(even) {
    background-color: $light-grey;
  }

  &:hover {
    background-color: $light-cyan;
  }
}

.tableCell {
  text-align: center;
  vertical-align: middle;
}

.emptyTableRow {
  height: 300px;
}
