@import '../../styles/fonts';
@import '../../styles/colors';

.root {
  margin: 40px 15%;
}

.logoHolder {
  height: 120px;
  display: flex;
  justify-content: center;
}

.heading {
  @include heading;
  margin-bottom: 8px;
  text-align: center;
}

.subHeading {
  @include textSmall;
  margin-top: 0;
  color: $grey;
  text-align: center;
}

.logoImg {
  height: 100%;
}

.orgList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
  gap: 40px;
}

.orgCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 140px;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid $light-grey;
  box-shadow: 0 0 0 -15px $light-grey;
  transition: transform 0.5s, box-shadow 0.3s;
  user-select: none;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 25px 30px -20px $light-grey;
  }
}

.orgName {
  @include headingMedium;
  text-align: center;
  color: $black;
  transition: all 0.3s;
  margin-bottom: 16px;

  .orgCard:hover > & {
    color: $cyan;
  }
}

.orgType {
  @include textSmall;
  text-transform: capitalize;
  text-align: center;
}

.logoutButton {
  position: absolute;
  top: 12px;
  right: 80px;
}

.searchInputContainer {
  width: 50%;
  position: relative;
  margin: 20px auto 0;

  .searchInput {
    letter-spacing: normal;
    text-transform: none;
    text-decoration: none;
    color: #0a1629;
    font-size: 14px;
    font-weight: 400;
    resize: none;
    border: 1px solid #e4e4e4;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    padding: 0 12px;
    transition: all 0.1s ease;
    outline: none;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto;
  }
}
