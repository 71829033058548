@import '../../styles/colors';
@import '../../styles/fonts';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  & + .root {
    margin-top: 20px;
  }
}

.imageBoxContainer {
  width: 150px;
  height: auto;
  min-height: 150px;
  position: relative;

  &.vertical {
    width: 200px;
    height: 200px;
  }
}

.imageBox {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.imageContainer {
  width: auto;
  height: 150px;
  min-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.imagePlaceholder {
  @include textMedium;
  border: 1px solid $light-grey;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey-2;
  user-select: none;
  flex-direction: column;
  gap: 10px;
}

.inputContainer {
  width: 100%;
}

.inputHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 10px;

  &.vertical {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
}

.imgUrlInput {
  display: block;
  flex: 1 1 auto;
}

.errorMessage {
  @include textSmall;
  color: $red;
  position: absolute;
}

.aspectRatioNotificationWrapper {
  background-color: transparentize($red, 0.75);
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;

  p {
    color: red;
    font-size: 12px;
    line-height: 1.25;

    + p {
      margin-top: 5px;
    }
  }
}
