@import '../../../../styles/fonts';

.root {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.dateBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.dateLabel {
  @include textSmall;
}

.dateInput {
  width: 120px;
}
