@import '../../styles/colors';
@import '../../styles/fonts';

:global {
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  input {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: $cyan;

    &:hover {
      background-color: $green;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $light-grey;
    color: $black;

    &:hover {
      background-color: $grey-2;
    }
  }

  .react-datepicker {
    border-radius: 12px;
    overflow: hidden;
  }

  .react-datepicker-wrapper input {
    @include textMedium;
    resize: none;
    border: 1px solid;
    border-color: $light-grey;
    margin-top: 4px;
    width: 100%;
    height: 38px;
    border-radius: 8px;
    padding: 0 12px;
    transition: all 0.1s ease;
    outline: none;

    &:focus-visible {
      border-color: $grey;
    }
  }
}

.hasError {
  border-color: $red;
}

.errorMessage {
  color: $red;
  @include textSmall;
  position: absolute;
}
