@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  width: 100%;
  height: 100%;
  background-color: $white;
  border: 1px solid $light-grey;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
}

.imageBlock {
  margin-top: 18px;
  width: 200px;
}

.fieldsBlock {
  flex-grow: 1;
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 18px 0 0;

  &:first-child {
    margin: 0;
  }
}

.formInput {
  width: 100%;
}

.textarea {
  width: 100%;
  padding: 10px;
  height: 150px;
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  margin-bottom: 2px;
  color: $grey-2;
  margin: 10px 0;
}

.submitButton {
  width: 100%;
}

.inlineActions {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
}

.red {
  color: $red;
  font-size: 12px;
}

.error {
  color: $red;
  font-size: 12px;
  margin: 5px 0;
}
