@import '../../styles/colors';
@import '../../styles/fonts';

.root {
  display: inline-block;
  width: 100%;
}

.input {
  @include textMedium;
  resize: none;
  border: 1px solid;
  border-color: $light-grey;
  width: 100%;
  height: 38px;
  border-radius: 8px;
  padding: 0 12px;
  transition: all 0.1s ease;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus-visible {
    border-color: $grey;
  }
}

.hasError {
  border-color: $red;
}

.errorMessage {
  @include textSmall;
  color: $red;
  margin: 5px 0;
}

.fakeSwitcher {
  display: flex;
  position: relative;
  width: 40px;
  height: 16px;
  background: $grey;
  border-radius: 22px;
  appearance: none;
  margin: 16px 0 0;

  &.disabled {
    opacity: 0.5;
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -3px;
    left: 0px;
    right: auto;
    background-color: $white;
    position: absolute;
    box-shadow: 0px 2px 5px 0px $grey;
  }

  &.fakeSwitcherEnabled {
    background: $green;

    &:before {
      right: 0px;
      left: auto;
    }
  }
}
