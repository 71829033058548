.root {
  display: flex;
  align-items: center;
  width: 10%;
}

.numberSelect {
  font-size: 14px;
  cursor: pointer;
  min-width: 80px;

  [class$='-control'] {
    border-color: #b2b9c4;
  }

  [class$='-menu'] {
    top: auto;
    bottom: 100%;
  }
}

.showLabel {
  font-size: 14px;
  margin-right: 10px;
}
