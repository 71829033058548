@import '../../../styles/colors';
@import '../../../styles/fonts';

$headerHeight: 56px;

.root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 30px;
  height: $headerHeight;
  min-height: $headerHeight;
  box-shadow: 0 -10px 10px 10px $light-grey;
  display: flex;
}

.navigationMenu {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.logoContainer {
  height: 100%;
}

.logoImg {
  height: 100%;
}

.navLink {
  @include textMedium;
  text-decoration: none;
  color: $black;
  position: relative;
  display: flex;
  align-items: center;
}

.currentPath {
  @include headingSmall;

  &::after {
    content: '';
    transition: all 0.3s ease;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: $cyan;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
}

.content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 18px;
}

.logoutButton {
  margin-top: 8px;
}

.notificationsHolder {
  position: absolute;
  top: $headerHeight;
  width: 100%;
}
