$black: #000000;
$white: #ffffff;
$dark-grey: #494852;
$grey: #999999;
$grey-2: #b2b9c4;
$light-grey: #efefef;
$light-grey-2: #fafbfc;
$light-grey-3: #f9fbfd;
$text-default-color: #11142d;

$green: #58c5c8;
$green-2: #6dc427;
$light-green-2: #c5e2b1;
$cyan: #27bac2;
$light-cyan: #27bac222;
$blue: #377dff;
$light-blue: #eaf3ff;
$red: #dd3939;
$light-red: #fdc3c4;
$orange: #ffa500;
$light-orange: #fedab7;
