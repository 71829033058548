@import '../../../../styles/fonts';
@import '../../../../styles/colors';

.root :global(table) {
  table-layout: fixed;
}

.root :global {
  td {
    text-align: left;
    padding: 0 10px;
  }

  td:nth-child(1) {
    text-align: center;
    padding: 0;
  }

  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(6) {
    overflow: hidden;
    white-space: nowrap;

    span {
      padding: 0 10px 0 0;
    }

    &.tooltip {
      overflow: visible !important;
    }

    .truncate {
      display: block;
      padding: 5px 10px 5px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.editIcon {
  height: 20px;
  margin: 0 10px 0;
  cursor: pointer;
  fill: $cyan;
}

.controlsHolder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: -30px 0 10px 0;
}

.roundButtonHolder {
  display: flex;
  align-items: center;
  height: 0;
  grid-gap: 16px;
  gap: 16px;
  position: sticky;
  bottom: 36px;
  margin-top: -80px;

  button {
    right: 15px;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: 0.2s;
    height: 54px;
    width: 54px;

    &:hover {
      opacity: 0.9;
    }

    span {
      color: #fff;
      font-size: 32px;
      line-height: 32px;
    }
  }
}

.roundButton {
  height: 44px;
  width: 44px;
  border: none;
  outline: none;
  cursor: pointer;
  background: $blue;
  border-radius: 100%;
  box-shadow: 0px 5px 10px -5px $grey;
  transition: all 0.3s;

  svg {
    fill: $white;
    height: 24px;
    width: 24px;
  }
}

.textWidth {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;
}

.shortCodeIdCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.copyButton {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.copyIcon,
.downloadIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;

  &:active,
  &:hover {
    path {
      fill: $cyan;
    }
  }
}

.downloadIcon {
  margin-top: -4px;
}

.popover {
  background-color: $white;
  box-shadow: 1px 1px 2px 2px $light-grey;
  border-radius: 8px;
  padding: 10px;
  margin-left: -25px;

  .downloadText {
    font-size: 12px;
    font-weight: bold;
    color: $dark-grey;
    margin-bottom: 10px;
  }

  .formatButton {
    background-color: $light-grey;
    color: $dark-grey;
    border: 0;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: $green;
      color: $white;
    }
  }
}
