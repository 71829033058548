@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  width: 326px;
  height: 152px;
  border-radius: 15px;
  border: 1px solid $light-grey;
  box-shadow: 0 25px 30px -20px $light-grey;
  padding: 22px 24px 28px;
  background: $white;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  }
}

.campaignInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.campaignName {
  @include headingMedium;
}

.campaignImageHolder {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.campaignImage {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.editButton {
  position: absolute;
  right: 20px;
  bottom: 14px;
  height: 25px;
  width: 25px;
  padding: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  background: $blue;
  border-radius: 100%;
  border: 3px solid $white;
  box-shadow: 0px 5px 10px -5px $grey;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 6px 11px -4px $grey;
  }
}

.iconEdit {
  fill: $white;
}

.campaignType {
  margin: 0;
  padding: 5px 10px;
  background-color: $cyan;
  color: $white;
  border-radius: 5px;
  text-transform: uppercase;
}
