@import '../../../styles/colors';

.root {
  background: linear-gradient($light-grey, $white 40%, $light-grey 80%);
  background-size: 100% 200%;
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  opacity: 1;

  animation: fadeIn 0.3s linear, gradient 1s linear infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position-y: 100%;
  }
  100% {
    background-position-y: -100%;
  }
}
