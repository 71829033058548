@import '../../styles/colors';

.progressBar {
  box-sizing: content-box;
  height: 6px;
  width: 50px;
  position: relative;
  margin: 10px;
  border-radius: 50px;
  padding: 4px;
  background: $light-red;

  & > span {
    display: block;
    height: 100%;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
  }

  &.red {
    background: $light-red;

    & > span {
      background-color: $red;
    }
  }

  &.orange {
    background: $light-orange;

    & > span {
      background-color: $orange;
    }
  }

  &.green {
    background: $light-green-2;

    & > span {
      background-color: $green-2;
    }
  }
}
