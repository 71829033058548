@import '../../styles/colors';
@import '../../styles/fonts';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabHeaders {
  display: flex;
  position: relative;
  gap: 15px;
}

.slider {
  transition: all 0.3s ease;
  position: absolute;
  bottom: 4px;
  height: 4px;
  border-radius: 3px;
  background: $blue;
}

.tabDefault {
  @include headingSmall;
  color: $dark-grey;
  background: none;
  cursor: pointer;
  border: none;
  position: relative;
  border-radius: 12px;
  height: 56px;
  padding: 0 20px;
  transition: all 0.2s;

  &:hover {
    background: $white;
  }

  &::after {
    content: '';
    width: 0;
    transition: width 0.2s;
  }
}

.tabDefault.active {
  background: $white;
  color: $blue;
}

.tabContent {
  flex-grow: 1;
  position: relative;
}

.tabButton {
  @include headingSmall;
  color: $dark-grey;
  background: $light-grey;
  cursor: pointer;
  border: none;
  position: relative;
  border-radius: 12px;
  height: 45px;
  padding: 0 23px;
  transition: all 0.2s;

  &:hover {
    background: $light-grey-2;
  }

  &:disabled {
    background: $light-grey-2;
    color: $grey;
    cursor: default;
  }
}

.tabButton.active {
  background: $cyan;
  color: $white;
}

.groupTabs {
  display: flex;
  flex-direction: row;
  gap: 15px;

  .tabGroupWrap {
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    border: solid 1px $light-grey;
    background-color: $white;
  }

  .tabGroupActionWrap {
    display: flex;
    flex-direction: column;
  }

  .tabGroupTitleWrap {
    display: flex;
    align-items: center;
    height: 54px;
    padding: 0 20px;
    border-bottom: solid 1px $light-grey;
    justify-content: space-between;

    h3 {
      color: $black;
      font-size: 15px;
    }

    a {
      cursor: pointer;
    }

    svg {
      fill: $grey;
    }
  }

  .tabGroupHeaders {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 22px;
    border: solid 1px $light-grey;
    background-color: $light-grey-2;
    gap: 15px;
    width: 274px;

    .tabButton {
      border-radius: 0;
      background: none;
      text-align: left;
      color: #11142d;
      padding: 0 18px;
      height: 44px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      width: 100%;

      &.active {
        background: $light-grey;
        color: $blue;
        font-weight: bold;
      }
    }
  }

  .tabContent {
    padding: 25px;
    border-radius: 22px;
    border: solid 1px #efefef;
    background-color: #fafbfc;
    grid-gap: 15px;
    gap: 15px;

    .tabContent {
      padding: 0;
      border: 0;
    }
  }

  .fakeSwitcher {
    display: flex;
    position: relative;
    width: 32px;
    height: 19px;
    background: $red;
    border-radius: 12px;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 3px;
      left: 3px;
      right: auto;
      background-color: $white;
      position: absolute;
    }

    &.fakeSwitcherEnabled {
      background: $green;

      &:before {
        right: 3px;
        left: auto;
      }
    }
  }

  .tabStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 12px;

    .rewardActive {
      color: $green;
    }

    .rewardPending,
    .rewardPaused {
      color: $orange;
    }

    .rewardExpired {
      color: $red;
    }

    .rewardDisabled,
    .rewardEmpty {
      color: $red;
      font-weight: 600;
    }
  }
}

.inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
