@import '../../../styles/colors';
@import '../../../styles/fonts';

.root {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.notification {
  width: 100%;
  height: 48px;
  @include headingSmall;
  background: $cyan;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  0% {
    height: 0;
  }
  100% {
    height: 48px;
  }
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  right: 24px;
  top: 12px;
  height: 24px;
  fill: $white;
}
