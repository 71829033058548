@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  margin-top: 10px;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.form {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.label {
  @include textSmall;
  font-weight: 600;
  color: $grey-2;
  margin-bottom: 8px;
}

.smallInput {
  width: 255px;
}

.inputHolderGrow {
  flex-grow: 1;
  max-width: 580px;
}

.submit {
  width: 160px;

  span {
    display: flex;
    align-items: center;
  }
}

.error {
  font-size: 12px;
  color: red;
  margin: 5px 0;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 10px 10px 10px auto;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.checkboxInput {
  label {
    display: flex;
    gap: 5px;
    align-items: center;

    input {
      width: 16px;
      height: 16px;
    }

    p {
      margin: 0;
    }
  }
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 0 18px 0;

  .inputHolder {
    position: relative;

    .deleteInputValue {
      position: absolute;
      height: 20px;
      width: 20px;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
      fill: #ccc;
    }
  }
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  margin-bottom: 2px;
  color: $grey-2;
}

.textInput {
  padding: 10px 0 0;
  font-size: 14px;
}
