@import '../../../../../styles/colors';
@import '../../../../../styles/fonts';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 54px;
  padding-bottom: 30px;
  padding-top: 30px;

  .pagination {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
  }

  .paginationButton + .paginationButton:not(.numberButton) {
    margin-left: 5px;
  }
}

.pageNumberButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 12px;
}

.paginationButton {
  @include textMedium;
  height: 40px;
  width: auto;
  padding: 0 15px;
  background-color: $white;
  color: $black;
  border: 1px solid $grey-2;
  cursor: pointer;
  border-radius: 8px;

  &.numberButton {
    width: 40px;
    padding: 0;
  }

  &.active {
    background-color: $cyan;
    border-color: $cyan;
    color: $white;
    font-weight: 600;
  }

  &:disabled {
    color: $grey-2;
    cursor: default;
    border-color: $light-grey;
  }

  &:hover:not(.active):not(:disabled) {
    background-color: $light-grey;
  }
}
