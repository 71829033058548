@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  display: flex;
  width: 100%;
  height: 100%;
}

.contentHolder {
  margin: 28px 8px;
}
