@import '../../../../styles/fonts';
@import '../../../../styles/colors';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 87px;
  border-radius: 20px;
  padding: 18px 28px 20px;
  gap: 20px;
  border: solid 1px #e8e8e8;
  background-color: #fafbfc;
  margin: 40px 0;
}

.campaignImg {
  width: 45px;
  height: 45px;
  border-radius: 15px;
  object-fit: contain;
}

.campaignName {
  @include headingMedium;
}

.campaignManagerLabel {
  @include textMedium;
  text-transform: uppercase;
  color: $grey;
}

.backButton {
}
