.tableControls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: -25px 0 10px 0;
}

.root :global(table) {
  table-layout: fixed;

  td {
    padding: 0 10px;
    text-align: left;
  }
}
