@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.languageSelect {
  width: 250px;
}

.addLanguageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.formHolder {
  margin-top: 12px;
}

.roundButton {
  width: 44px;
  height: 44px;
  box-shadow: 0 3px 5px 2px $light-grey;
  background-color: $white;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left: 12px;
}

.addIcon {
  fill: $cyan;
}

.heading {
  @include headingMedium;
}

.label {
  @include headingSmall;
  color: $grey;
}

.formInputContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 0 18px 0;

  .inputHolder {
    position: relative;

    .deleteInputValue {
      position: absolute;
      height: 20px;
      width: 20px;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
      fill: #ccc;
    }
  }
}

.inputLabel {
  @include textSmall;
  font-weight: 600;
  margin-bottom: 2px;
  color: $grey-2;
}

.textInput {
  padding: 10px 0 0;
  font-size: 14px;
}
